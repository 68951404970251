// Image Listing
import ImageListingTemplate from './components/Blocks/Listing/ImageListingTemplate';

// Blog Post View
import PostView from './components/View/PostView';

// Sketch View
import SketchView from './components/View/SketchView';

// Blog Listing
import PostItemTemplate from './components/Blocks/Listing/PostItemTemplate';

const applyConfig = (config) => {
  config.settings = {
    ...config.settings,
    isMultilingual: true,
    supportedLanguages: ['pt-br', 'en'],
    defaultLanguage: 'en',
    apiExpanders: [
      ...config.settings.apiExpanders,
      {
        match: '',
        GET_CONTENT: [
          'breadcrumbs',
          'navigation',
          'actions',
          'authors',
          'types',
        ],
        querystring: {
          'expand.navigation.depth': 2,
        },
      },
    ],
    image_crop_aspect_ratios: [
      {
        label: '16:9',
        ratio: 16 / 9,
      },
      {
        label: '4:3',
        ratio: 4 / 3,
      },
      {
        label: '1:1',
        ratio: 1,
      },
    ],
  };
  config.views.contentTypesViews = {
    ...config.views.contentTypesViews,
    Post: PostView,
    Sketch: SketchView,
  };
  config.registerComponent({
    name: 'GridListingItemTemplate',
    component: PostItemTemplate,
    dependencies: 'Post',
  });
  config.blocks.blocksConfig.listing.variations = [
    ...config.blocks.blocksConfig.listing.variations,
    {
      id: 'image_grid',
      isDefault: false,
      title: 'Image Grid',
      template: ImageListingTemplate,
    },
  ];

  // Grid Block
  // Remove old Grid Block
  if (config.blocks.blocksConfig.__grid !== undefined) {
    config.blocks.blocksConfig.__grid.restricted = true;
  }
  config.blocks.blocksConfig.gridBlock.allowedBlocks = [
    ...config.blocks.blocksConfig.gridBlock.allowedBlocks,
    'facebookBlock',
    'instagramBlock',
    'tweetBlock',
    'video',
  ];
  config.blocks.blocksConfig.gridBlock.blocksConfig = {
    ...config.blocks.blocksConfig.gridBlock.blocksConfig,
    facebookBlock: config.blocks.blocksConfig.facebookBlock,
    instagramBlock: config.blocks.blocksConfig.instagramBlock,
    tweetBlock: config.blocks.blocksConfig.tweetBlock,
  };
  return config;
};

export default applyConfig;
