import React from 'react';
import { Component } from '@plone/volto/components';
import { FormattedDate, Icon } from '@plone/volto/components';
import clockSVG from '@plone/volto/icons/clock.svg';
import tagSVG from '@plone/volto/icons/tag.svg';

const PostItemTemplate = ({ item }) => {
  const tags = item?.Subject;
  let img = undefined;
  if (item.image_field !== '') {
    img = { ...item, '@id': item.image_scales.preview_image_link[0].base_path };
  }
  return (
    <div className="card-container post">
      {img && (
        <div className="item-image-box">
          <Component
            componentName="PreviewImage"
            item={img}
            alt=""
            className="item-image"
          />
        </div>
      )}
      <div className="item">
        <div className="meta">
          <div className="meta-wrapper">
            <div className="effective">
              <Icon name={clockSVG} />
              <FormattedDate date={item.effective} locale={'de'} />
            </div>
            <div className="tags">
              {tags && tags.length > 0 ? (
                <>
                  <Icon name={tagSVG} />
                  {tags.map((tag, i) => (
                    <>
                      <span className="ui tag" key={tag}>
                        {tag}
                      </span>
                      {tags.length > i + 1 && (
                        <span className="divider">,</span>
                      )}
                    </>
                  ))}
                </>
              ) : (
                <span />
              )}
            </div>
          </div>
        </div>
        <div className="content">
          <h2 className="title">{item?.title}</h2>
          <p className="description">{item?.description}</p>
        </div>
      </div>
    </div>
  );
};

export default PostItemTemplate;
