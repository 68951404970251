import React from 'react';
import PropTypes from 'prop-types';
import Container from '@kitconcept/volto-light-theme/components/Atoms/Container/Container';
import Image from '@plone/volto/components/theme/Image/Image';
import { UniversalLink } from '@plone/volto/components';
import config from '@plone/volto/registry';

const ImageListingTemplate = ({ items }) => {
  const { settings } = config;
  const renderItems = items.filter(
    (content) =>
      settings.imageObjects.includes(content['@type']) && content.image_field,
  );

  return (
    <Container className={'image-listing-grid'}>
      {renderItems.length > 0 ? (
        renderItems.map(function (item, i) {
          const img = item.image_scales?.image[0];
          const scale =
            img.scales?.large !== undefined ? img.scales.large : img;
          const href = scale.download;
          const width = scale.width;
          const height = scale.height;
          return (
            <div key={i} className={'image-listing-item'}>
              <UniversalLink className="image-link" href={`${item['@id']}`}>
                <img
                  src={`${item['@id']}/${href}`}
                  alt={item.title}
                  style={{ aspectRatio: `${width}/${height}` }}
                />
              </UniversalLink>
            </div>
          );
        })
      ) : (
        <span />
      )}
    </Container>
  );
};

ImageListingTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default ImageListingTemplate;
