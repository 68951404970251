import React from 'react';
import Container from '@kitconcept/volto-light-theme/components/Atoms/Container/Container';

const blockedTypes = ['Plone Site'];

const ContentTitle = ({ content }) => {
  const contentType = content?.['@type'];
  const title = content?.title;
  const display = !blockedTypes.includes(contentType) && title;
  return display ? (
    <Container layout>
      <h1 className="documentFirstHeading">{title}</h1>
    </Container>
  ) : null;
};

export default ContentTitle;
