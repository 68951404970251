/**
 * SketchView view component.
 * @module components/View/SketchView
 */

import React from 'react';
import PropTypes from 'prop-types';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import Image from '@plone/volto/components/theme/Image/Image';
import cx from 'classnames';
import Container from '@kitconcept/volto-light-theme/components/Atoms/Container/Container';

const SketchImage = ({ content }) => {
  const caption = content.description;
  return (
    <figure className={cx('figure sketch image align detached large')}>
      <Image
        item={content}
        imageField={'preview_image'}
        alt={caption}
        title={caption}
      />
    </figure>
  );
};

/**
 * SketchView view component class.
 * @function SketchView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const SketchView = ({ content }) => {
  const hasImage = content?.preview_image ? true : false;

  return (
    <Container id="page-document" className="view-wrapper post-view">
      {hasImage && (
        <SketchImage content={content} caption={content.image_caption} />
      )}
      <RenderBlocks content={content} />
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
SketchView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default SketchView;
