/**
 * PostView view component.
 * @module components/View/PostView
 */

import React from 'react';
import PropTypes from 'prop-types';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import Image from '@plone/volto/components/theme/Image/Image';
import cx from 'classnames';
import Container from '@kitconcept/volto-light-theme/components/Atoms/Container/Container';
import DocumentByLine from '../DocumentByLine/DocumentByLine';

const PostImage = ({ content }) => {
  const caption = content.preview_caption_link
    ? content.preview_caption_link
    : content.title;
  const img = content.preview_image_link;
  return (
    <figure className={cx('figure post image align detached large')}>
      <Image item={img} image_field={'image'} alt={caption} title={caption} />
    </figure>
  );
};

/**
 * PostView view component class.
 * @function PostView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const PostView = ({ content }) => {
  const hasImage = content?.preview_image_link ? true : false;

  return (
    <Container id="page-document" className="view-wrapper post-view">
      {hasImage && (
        <PostImage content={content} caption={content.image_caption} />
      )}
      <DocumentByLine
        content={content}
        showAuthor={true}
        showPublished={true}
      />
      <RenderBlocks content={content} />
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
PostView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default PostView;
