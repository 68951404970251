// SemanticUI-free pre-@plone/components
import PropTypes from 'prop-types';

import { Logo, Navigation, SearchWidget } from '@plone/volto/components';

const Header = (props) => {
  const { pathname } = props;

  return (
    <header className="header-wrapper">
      <div className="header">
        <div className="header-nav-wrapper">
          <Navigation pathname={pathname} />
        </div>
        <div className="header-logo-wrapper">
          <div className="left-column" />
          <div className="logo">
            <Logo />
          </div>
          <div className="search-wrapper">
            <div className="search">
              <SearchWidget />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  token: PropTypes.string,
  pathname: PropTypes.string.isRequired,
};

Header.defaultProps = {
  token: null,
};

export default Header;
